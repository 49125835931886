export default function ({ vars, sparkyFramework }) {
  return function tsgLoad({ auth, onTsgUnload }) {               
    if (auth.instances?.isRunningStatus('strata_insights')) {
      onTsgUnload(({ auth }) => { 
        return { requireReload: true } // always reload browser when switch TSG 
      })
      return { // can be not a function, functions rerun when force rerender
        navigation: () => [{
          key: 'manage',
          title: 'Manage',
          path: '/manage',
          mergeNav: true,
          expandable: true,
          children: [
            {
              key: 'security-posture',
              mergeNav: true,      
              path: '/manage/security-posture',
              title: 'Security Posture',
              expandable: true,
              children: [
                {
                  key: 'policy-analyzer',
                  path: `/manage/security-posture/policy-analyzer`,
                  title: 'Policy Analyzer',
                  noRBACheck: true,            
                  availFallbackRedirect: 'no-redirect',
                  isSetup: true,
                  mapStateToProps: ({auth}) =>  {                     
                    return { auth};
                  },
                  contentClassName: "panwds-tw3", 
                  component: () => import('./containers/PolicyAnalysis') 
                },
              ],
            },
          ],
        }]
      } 
    } else {
      return;
    }
  } 
 }